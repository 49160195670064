import { useSpring, animated } from "react-spring";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const transA = (x, y) => `translate3d(${x / 32}px,${y / 32}px,0)`;
const transB = (x, y) => `translate3d(${x / 25}px,${y / 25}px,0)`;
const transC = (x, y) => `translate3d(${x / 42}px,${y / 42}px,0)`;

export default function Abstracto() {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: {
      mass: 12.4,
      tension: 210,
      friction: 9,
      velocity: 9,
    },
  }));
  return (
    <div onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <svg
        width="300"
        height="600"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animated.path
          style={{ transform: props.xy.interpolate(transC) }}
          d="M91.9 95.9969C91.9 118.603 73.5884 136.928 51 136.928C28.4116 136.928 10.1 118.603 10.1 95.9969C10.1 73.3911 28.4116 55.0657 51 55.0657C73.5884 55.0657 91.9 73.3911 91.9 95.9969Z"
          fill="#FFF76A"
          fillOpacity="0.45"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M135.479 108.238L129.62 96.9619L204.842 48.3757L210.702 59.6514L135.479 108.238Z"
          fill="#FFF76A"
          fillOpacity="0.45"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M117.762 176.471L87.2635 134.614L79.913 140.056L110.43 182.986L117.762 176.471Z"
          fill="#75CFB8"
          fillOpacity="0.45"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transB) }}
          d="M46.4668 156.327L49.6725 161.47L13.82 190.672L10.1081 182.543L46.4668 156.327Z"
          fill="#FFF76A"
          fillOpacity="0.45"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M76.0915 167.7L79.0308 165.498L92.3702 187.627L89.431 189.829L76.0915 167.7Z"
          fill="#75CFB8"
          fillOpacity="0.45"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transB) }}
          d="M91.0197 127.566L96.8215 123.842L110.723 142.715L104.554 148.176L91.0197 127.566Z"
          fill="#75CFB8"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M71.3748 123.616L64.3216 113.34L209.476 0.14266L221.86 17.2903L71.3748 123.616Z"
          fill="#75CFB8"
          fillOpacity="0.75"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M77.6043 132.616L73.4865 125.816L123.591 95.2679L127.123 102.36L77.6043 132.616Z"
          fill="#FFF76A"
          fillOpacity="0.45"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transB) }}
          d="M10.1191 217.59L5.44005 211.382L48.4208 171.115L53.0999 177.322L10.1191 217.59Z"
          fill="#75CFB8"
          fillOpacity="0.45"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M38.6336 182.693L35.3425 178.655L53.1728 162.78L56.4639 166.818L38.6336 182.693Z"
          fill="#75CFB8"
          fillOpacity="0.45"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transB) }}
          d="M155.734 107.233L153.901 103.237L203.601 72.8165L205.434 76.813L155.734 107.233Z"
          fill="#75CFB8"
          fillOpacity="0.35"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M160.231 72.1432L158.154 68.2997L206.167 36.0272L208.245 39.8707L160.231 72.1432Z"
          fill="#75CFB8"
          fillOpacity="0.85"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M195.993 87.5844L145.893 116.149L146.378 117.206L196.478 88.6408L195.993 87.5844Z"
          fill="#75CFB8"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transB) }}
          d="M21.4549 67.3269L23.7858 65.263L110.997 187.685L108.666 189.749L21.4549 67.3269Z"
          fill="#75CFB8"
          fillOpacity="0.49"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M13.1786 83.4293L22.2105 76.8745L80.8086 156.102L70.835 164.449L13.1786 83.4293Z"
          fill="#75CFB8"
          fillOpacity="0.45"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transB) }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.747 153.974C139.869 153.139 141.798 149.655 141.053 146.192C140.308 142.729 137.172 140.598 134.049 141.434C130.926 142.269 128.998 145.753 129.743 149.216C130.488 152.679 133.624 154.809 136.747 153.974Z"
          fill="#75CFB8"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M23.8573 138.849C25.1896 145.041 21.7405 151.258 16.1756 152.746C10.6157 154.233 5.01837 150.443 3.68673 144.254C2.35446 138.061 5.80351 131.844 11.3684 130.356L11.3426 130.26L11.3684 130.356C16.9285 128.869 22.5257 132.66 23.8573 138.849Z"
          fill="#75CFB8"
          fillOpacity="0.45"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transB) }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.454 103.207C63.9008 102.553 65.4111 99.8231 64.8274 97.1099C64.2437 94.3967 61.787 92.7277 59.3402 93.3821C56.8934 94.0364 55.3832 96.7663 55.9669 99.4794C56.5505 102.193 59.0072 103.862 61.454 103.207Z"
          fill="#75CFB8"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M34.6307 142.933L34.6307 142.933C37.0017 142.298 39.3968 143.913 39.9677 146.567C40.5391 149.223 39.0585 151.882 36.6826 152.517C34.3117 153.151 31.9166 151.536 31.3457 148.883C30.7743 146.227 32.2549 143.568 34.6307 142.933Z"
          fill="#75CFB8"
          fillOpacity="0.45"
          stroke="white"
          strokeWidth="0.2"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M63.685 108.573L105.13 76.6681L104.779 76.1009L63.3336 108.006L63.685 108.573Z"
          fill="#75CFB8"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M147.7 178.988L169.135 207.006L169.924 206.256L148.488 178.238L147.7 178.988Z"
          fill="#FFF76A"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          d="M12.7678 40.1161L34.2147 69.9742L34.6973 69.5432L13.2504 39.6852L12.7678 40.1161Z"
          fill="#75CFB8"
          fillOpacity="0.45"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transB) }}
          d="M27.2458 184.641L0 209.202L0.388755 209.737L27.6346 185.177L27.2458 184.641Z"
          fill="#75CFB8"
          fillOpacity="0.45"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transA) }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167.359 154.221L161.613 146.851L161.099 147.349L166.845 154.719L160.234 161.126L160.681 161.699L167.292 155.292L173.039 162.662L173.553 162.164L167.806 154.794L174.417 148.387L173.969 147.814L167.359 154.221Z"
          fill="white"
        />
        <animated.path
          style={{ transform: props.xy.interpolate(transB) }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8635 141.57L10.9043 137.775L10.6397 138.031L13.599 141.826L10.1947 145.126L10.4247 145.421L13.829 142.121L16.7882 145.917L17.0528 145.66L14.0935 141.865L17.4978 138.566L17.2678 138.271L13.8635 141.57Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
