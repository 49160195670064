import { useEffect, useLayoutEffect, useState, useContext, memo } from "react";
import { withSize } from "react-sizeme";
import { config, useSpring } from "react-spring";

import landingPhoto from "../../Img/emmanuel_header.png";
import Abstracto from "../../Img/Abstracto";
import Idioma from "../../Img/Idioma";
import EmojiQuienSoy from "../../Img/emoji_quiensoy.svg";
import EmojiTrabajo from "../../Img/emoji_trabajo.svg";
import EmojiContacto from "../../Img/emoji_contacto.svg";
import MagicianEmoji from "../../Img/MagicianEmoji.svg";
import EmojiUp from "../../Img/EmojiUp.svg";

import { AppContext } from "../../Provider/config";

import {
  foreword,
  ContactCallToAction,
  WhoIAmCallToAction,
  BoilerPlateCallToAction,
  WorkCallToAction,
} from "../../languages.json";

function Header({ size }) {
  const [scroll, setScroll] = useState(null);
  const [context, setContext] = useContext(AppContext);
  let languageContext = context && context.language && context.language;
  let scrollMyWork = context && context.scrollMyWork && context.scrollMyWork;
  let scrollWhoIAm = context && context.scrollWhoIAm && context.scrollWhoIAm;
  let scrollOutOfWork =
    context && context.scrollOutOfWork && context.scrollOutOfWork;
  let propsSize = size.width;
  const handleScroll = () => {
    let position = window.pageYOffset;
    setScroll(position);
  };

  useLayoutEffect(() => {
    setContext({
      ...context,
      focusNavigation: 0,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const emojiMenuOnScroll = () => {
    if (scroll <= 180) {
      return "flex flex-row select-none absolute left-0 right-0 justify-center text-center top-6 pt-16";
    } else {
      return "flex flex-col select-none fixed right-0 pt-64";
    }
  };

  const [y, setY, stop] = useSpring(() => ({
    immediate: false,
    y: 0,
    onFrame: (props) => {
      window.scroll(0, props.y);
    },
  }));

  const onLanguageChange = (type) => {
    let spanishLanguage =
      languageContext === undefined || languageContext === false;
    switch (type) {
      case "foreword":
        return spanishLanguage ? foreword["es"] : foreword["en"];
      case "who-i-am":
        return spanishLanguage
          ? WhoIAmCallToAction["es"]
          : WhoIAmCallToAction["en"];
      case "my-work":
        return spanishLanguage
          ? WorkCallToAction["es"]
          : WorkCallToAction["en"];
      case "boilerPlate":
        return spanishLanguage
          ? BoilerPlateCallToAction["es"]
          : BoilerPlateCallToAction["en"];
      case "contact-me":
        return spanishLanguage
          ? ContactCallToAction["es"]
          : ContactCallToAction["en"];
      default:
        break;
    }
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 2,
        }}
      >
        <Abstracto />
      </div>
      <div className="flex justify-center shadow-lg bg-gradient-to-b from-azul-marino to-crema-bajo mt-16 rounded-lg pt-12 h-portada-nombre mx-6 md:mx-24 lg:mx-28">
        <div className="flex flex-col">
          <div
            style={{
              position: "absolute",
              right: "15%",
              zIndex: 5,
            }}
          >
            <Idioma />
          </div>
          {scroll >= 180 && (
            <button
              style={{
                position: "fixed",
                zIndex: 3,
                bottom: 0,
                right: 0,
                backgroundColor: "#FFF76A",
                borderRadius: "50%",
                margin: "10px 12px",
              }}
              onClick={() => {
                setY({ y: 0 });
              }}
              className="text-sm md:text-2xl select-none hover:text-amarillo-alegria font-Manu-Poppins mx-4 p-2 focus:outline-none hover:bg-opacity-75 opacity-60 focus:opacity-100"
            >
              <img
                loading="lazy"
                style={{
                  width: propsSize <= 767 ? 30 : 64,
                  height: propsSize <= 767 ? 30 : 64,
                }}
                src={EmojiUp}
              />
            </button>
          )}
          {propsSize >= 640 && (
            <div
              className={emojiMenuOnScroll()}
              style={{ zIndex: 3, top: scroll >= 180 ? "-10%" : 0 }}
            >
              <button
                style={{
                  backgroundColor: scroll >= 180 && "#FFF76A",
                  borderRadius: scroll >= 180 && "50%",
                  margin: scroll >= 180 && "4px 12px",
                }}
                onClick={() => {
                  setY({ y: scrollWhoIAm });
                  setContext({
                    ...context,
                    focusNavigation: 1,
                  });
                }}
                className={`flex h-auto md:text-xl opacity-60 hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none ${
                  context &&
                  context.focusNavigation &&
                  context.focusNavigation === 1 &&
                  "opacity-100"
                }`}
              >
                {scroll >= 180 ? (
                  <img
                    loading="lazy"
                    style={{
                      width: propsSize <= 767 ? 30 : 64,
                      height: propsSize <= 767 ? 30 : 64,
                    }}
                    src={EmojiQuienSoy}
                  />
                ) : (
                  onLanguageChange("who-i-am")
                )}
              </button>
              <button
                style={{
                  backgroundColor: scroll >= 180 && "#FFF76A",
                  borderRadius: scroll >= 180 && "50%",
                  margin: scroll >= 180 && "4px 12px",
                }}
                className={`flex h-auto md:text-xl opacity-60 hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none ${
                  context &&
                  context.focusNavigation &&
                  context.focusNavigation === 2 &&
                  "opacity-100"
                }`}
                onClick={() => {
                  setY({ y: scrollMyWork });
                  setContext({
                    ...context,
                    focusNavigation: 2,
                  });
                }}
              >
                {scroll >= 180 ? (
                  <img
                    loading="lazy"
                    style={{
                      width: propsSize <= 767 ? 30 : 64,
                      height: propsSize <= 767 ? 30 : 64,
                    }}
                    src={EmojiTrabajo}
                  />
                ) : (
                  onLanguageChange("my-work")
                )}
              </button>
              <button
                style={{
                  backgroundColor: scroll >= 180 && "#FFF76A",
                  borderRadius: scroll >= 180 && "50%",
                  margin: scroll >= 180 && "4px 12px",
                }}
                className={`flex h-auto md:text-xl opacity-60 hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none ${
                  context &&
                  context.focusNavigation &&
                  context.focusNavigation === 3 &&
                  "opacity-100"
                }`}
                onClick={() => {
                  setY({ y: scrollOutOfWork });
                  setContext({
                    ...context,
                    focusNavigation: 3,
                  });
                }}
              >
                {scroll >= 180 ? (
                  <img
                    loading="lazy"
                    style={{
                      width: propsSize <= 767 ? 30 : 64,
                      height: propsSize <= 767 ? 30 : 64,
                    }}
                    src={MagicianEmoji}
                  />
                ) : (
                  onLanguageChange("boilerPlate")
                )}
              </button>
              <button
                style={{
                  backgroundColor: scroll >= 180 && "#FFF76A",
                  borderRadius: scroll >= 180 && "50%",
                  margin: scroll >= 180 && "4px 12px",
                }}
                onClick={() => {
                  window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0,
                    behavior: "smooth",
                  });
                  setContext({
                    ...context,
                    focusNavigation: 4,
                  });
                }}
                className={`flex h-auto md:text-xl opacity-60 hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none ${
                  context &&
                  context.focusNavigation &&
                  context.focusNavigation === 4 &&
                  "opacity-100"
                }`}
              >
                {scroll >= 180 ? (
                  <img
                    loading="lazy"
                    style={{
                      width: propsSize <= 767 ? 30 : 64,
                      height: propsSize <= 767 ? 30 : 64,
                    }}
                    src={EmojiContacto}
                  />
                ) : (
                  onLanguageChange("contact-me")
                )}
              </button>
            </div>
          )}
          {propsSize <= 640 && (
            <div
              className={emojiMenuOnScroll()}
              style={{ zIndex: 4, top: scroll >= 180 ? "-10%" : 0 }}
            >
              <button
                style={{
                  margin: "3px",
                  margin: "4px 12px",
                }}
                onClick={() => {
                  setY({ y: scrollWhoIAm });
                  setContext({
                    ...context,
                    focusNavigation: 1,
                  });
                }}
                className={`flex h-auto md:text-xl opacity-60 bg-amarillo-alegria hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none ${
                  context &&
                  context.focusNavigation &&
                  context.focusNavigation === 1 &&
                  "opacity-100"
                }`}
              >
                <img
                  loading="lazy"
                  style={{
                    width: propsSize <= 767 ? 30 : 64,
                    height: propsSize <= 767 ? 30 : 64,
                  }}
                  src={EmojiQuienSoy}
                />
              </button>
              <button
                style={{
                  margin: "3px",
                  margin: "4px 12px",
                }}
                className={`flex h-auto md:text-xl opacity-60 bg-amarillo-alegria hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none ${
                  context &&
                  context.focusNavigation &&
                  context.focusNavigation === 2 &&
                  "opacity-100"
                }`}
                onClick={() => {
                  setY({ y: scrollMyWork });
                  setContext({
                    ...context,
                    focusNavigation: 2,
                  });
                }}
              >
                <img
                  loading="lazy"
                  style={{
                    width: propsSize <= 767 ? 30 : 64,
                    height: propsSize <= 767 ? 30 : 64,
                  }}
                  src={EmojiTrabajo}
                />
              </button>
              <button
                style={{
                  margin: "3px",
                  margin: "4px 12px",
                }}
                className={`flex h-auto md:text-xl opacity-60 bg-amarillo-alegria hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none ${
                  context &&
                  context.focusNavigation &&
                  context.focusNavigation === 3 &&
                  "opacity-100"
                }`}
                onClick={() => {
                  setY({ y: scrollOutOfWork });
                  setContext({
                    ...context,
                    focusNavigation: 3,
                  });
                }}
              >
                <img
                  loading="lazy"
                  style={{
                    width: propsSize <= 767 ? 30 : 64,
                    height: propsSize <= 767 ? 30 : 64,
                  }}
                  src={MagicianEmoji}
                />
              </button>
              <button
                style={{
                  margin: "3px",
                  margin: "4px 12px",
                }}
                className={`flex h-auto md:text-xl opacity-60 bg-amarillo-alegria hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none ${
                  context &&
                  context.focusNavigation &&
                  context.focusNavigation === 4 &&
                  "opacity-100"
                }`}
                onClick={() => {
                  window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0,
                    behavior: "smooth",
                  });
                  setContext({
                    ...context,
                    focusNavigation: 4,
                  });
                }}
              >
                <img
                  loading="lazy"
                  style={{
                    width: propsSize <= 767 ? 30 : 64,
                    height: propsSize <= 767 ? 30 : 64,
                  }}
                  src={EmojiContacto}
                />
              </button>
            </div>
          )}
          <div className="flex mt-44 md:mt-32 md:mb-6 lg:mt-24 justify-center lg:-my-8">
            <h1
              style={{ zIndex: 1 }}
              className={`block select-none text-tinto text-6xl mx-2 md:-mb-16 md:text-8xl lg:-mb-8 lg:text-9xl xl:text-size-emmanuel-xl `}
            >
              {propsSize >= 0 && propsSize < 424 && (
                <img
                  loading="lazy"
                  className="select-none"
                  alt="Emmanuel"
                  style={{
                    width: 140,
                    height: 190,
                    top: 242,
                    transform: "translate(-52px, 0)",
                    opacity: 0.99,
                    filter: "grayscale(70%)",
                    position: "absolute",
                    zIndex: 1,
                  }}
                  src={`${landingPhoto}`}
                />
              )}
              {propsSize >= 425 && propsSize <= 764 && (
                <img
                  loading="lazy"
                  className="select-none"
                  alt="Emmanuel"
                  style={{
                    width: 160,
                    height: 210,
                    top: 223,
                    transform: "translate(-56px, 0)",
                    opacity: 0.99,
                    filter: "grayscale(70%)",
                    position: "absolute",
                    zIndex: 1,
                  }}
                  src={`${landingPhoto}`}
                />
              )}
              {propsSize >= 765 && propsSize < 1023 && (
                <img
                  loading="lazy"
                  className="select-none"
                  alt="Emmanuel"
                  style={{
                    width: 230,
                    height: 290,
                    top: 174,
                    transform: "translate(-70px, 0)",
                    opacity: 0.99,
                    filter: "grayscale(70%)",
                    position: "absolute",
                    zIndex: 1,
                  }}
                  src={`${landingPhoto}`}
                />
              )}
              {propsSize >= 1024 && propsSize < 1279 && (
                <img
                  loading="lazy"
                  className="select-none"
                  alt="Emmanuel"
                  style={{
                    width: 230,
                    height: 290,
                    top: 169,
                    transform: "translate(-70px, 0)",
                    opacity: 0.99,
                    filter: "grayscale(70%)",
                    position: "absolute",
                    zIndex: 1,
                  }}
                  src={`${landingPhoto}`}
                />
              )}
              {propsSize >= 1280 && (
                <img
                  loading="lazy"
                  className="select-none"
                  alt="Emmanuel"
                  style={{
                    width: 230,
                    height: 290,
                    top: 187,
                    transform: "translate(-70px, 0)",
                    opacity: 0.99,
                    filter: "grayscale(70%)",
                    position: "absolute",
                    zIndex: 1,
                  }}
                  src={`${landingPhoto}`}
                />
              )}
              <label className="text-capital-e-sm md:text-capital-e-md lg:text-capital-e-lg xl:text-capital-e-xl">
                {propsSize >= 426 && "E"}
                {propsSize <= 425 && "Em"}
              </label>
              {propsSize >= 426 && "mmanuel"}
            </h1>
          </div>
          <p
            style={{
              marginTop: propsSize <= 970 && "-20px",
              zIndex: 1,
            }}
            className="text-base text-tinto my-2 md:text-2xl font-Manu-Poppins text-center mx-6 md:mx-14 xl:mx-48 2xl:mx-72"
          >
            {onLanguageChange("foreword")}
          </p>
          <div
            className="flex flex-row justify-center text-center my-2"
            style={{ zIndex: 2 }}
          >
            <button
              onClick={() => {
                window.scrollTo({
                  top: document.body.scrollHeight,
                  left: 0,
                  behavior: "smooth",
                });
              }}
              className="text-sm text-tinto md:text-2xl font-Manu-Poppins mx-4 p-2 border-solid rounded-lg border-2 border-amarillo-alegria bg-amarillo-alegria focus:outline-none hover:bg-opacity-75"
            >
              {onLanguageChange("contact-me")}
            </button>
            <button
              onClick={() => {
                setY({ y: scrollMyWork });
              }}
              className="text-sm text-tinto md:text-2xl font-Manu-Poppins mx-4 p-2 border-solid rounded-lg border-2 border-amarillo-alegria bg-amarillo-alegria focus:outline-none hover:bg-opacity-75"
            >
              {onLanguageChange("my-work")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(withSize()(Header));
