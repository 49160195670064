import React, { useCallback, useState, useContext, memo } from "react";
import { animated, useTransition, config } from "react-spring";
import { AppContext } from "../../Provider/config";
import { withSize } from "react-sizeme";
import "../../Tailwind/css/gallery.css";

import RightEmoji from "../../Img/RightEmoji.svg";
import LeftEmoji from "../../Img/LeftEmoji.svg";
import CloseEmoji from "../../Img/CloseEmoji.svg";

//[Poda]
import webLandingPoda from "../../Img/Poda/Trabajo/web_landing.png";
import movil1 from "../../Img/Poda/Trabajo/movil1.png";
import movil2 from "../../Img/Poda/Trabajo/movil2.png";
import movil3 from "../../Img/Poda/Trabajo/movil3.png";
import movil4 from "../../Img/Poda/Trabajo/movil4.png";
import software3 from "../../Img/Poda/Trabajo/software3.png";
import software4 from "../../Img/Poda/Trabajo/software4.png";
import software5 from "../../Img/Poda/Trabajo/software5.png";
import software6 from "../../Img/Poda/Trabajo/software6.png";
import software7 from "../../Img/Poda/Trabajo/software7.png";
import software8 from "../../Img/Poda/Trabajo/software8.png";
import software9 from "../../Img/Poda/Trabajo/software9.png";
import software10 from "../../Img/Poda/Trabajo/software10.png";
import software11 from "../../Img/Poda/Trabajo/software11.png";
import software12 from "../../Img/Poda/Trabajo/software12.png";
import software13 from "../../Img/Poda/Trabajo/software13.png";
//[Poda]

//[Lavado]
import webLandingLavado from "../../Img/Lavado/web_landing.png";
import mobile1 from "../../Img/Lavado/mobile1.png";
import mobile2 from "../../Img/Lavado/mobile2.png";
import mobile3 from "../../Img/Lavado/mobile3.png";
import mobile4 from "../../Img/Lavado/mobile4.png";
import web1 from "../../Img/Lavado/web1.png";
import web2 from "../../Img/Lavado/web2.png";
import web3 from "../../Img/Lavado/web3.png";
import web4 from "../../Img/Lavado/web4.png";
import web5 from "../../Img/Lavado/web5.png";
import web6 from "../../Img/Lavado/web6.png";
import LavadoPostesA from "../../Img/Lavado/LavadoPostesA.jpg";
import LavadoPostesB from "../../Img/Lavado/LavadoPostesB.jpg";
//[Lavado]

//[DLT]
import dlt1 from "../../Img/DLT/dlt1.png";
import dlt2 from "../../Img/DLT/dlt2.png";
import dlt3 from "../../Img/DLT/dlt3.png";
import dlt4 from "../../Img/DLT/dlt4.png";
import dlt5 from "../../Img/DLT/dlt5.png";
// import DLTDeviceA from "../../Img/DLT/DLTDeviceA.jpg";
import DLTTeamA from "../../Img/DLT/DLTTeamA.jpg";
import DLTTeamC from "../../Img/DLT/DLTTeamC.jpg";
import DLTTeamD from "../../Img/DLT/DLTTeamD.jpg";
//[DLT]

//[STS]
import STSSoftwareA from "../../Img/STS/STSSoftwareA.png";
import STSSoftwareB from "../../Img/STS/STSSoftwareB.png";
import STSSoftwareC from "../../Img/STS/STSSoftwareC.png";
import STSSoftwareD from "../../Img/STS/STSSoftwareD.png";
import STSDeviceA from "../../Img/STS/STSDeviceA.jpg";
import STSDeviceB from "../../Img/STS/STSDeviceB.jpg";
//[STS]

const imagesPoda = [
  ({ style }) => (
    <animated.img loading="lazy" src={webLandingPoda} style={style} />
  ),
  ({ style }) => <animated.img loading="lazy" src={movil1} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={movil2} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={movil3} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={movil4} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software3} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software4} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software4} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software5} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software6} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software7} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software8} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software9} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software10} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software11} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software12} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={software13} style={style} />,
];

const imagesLavado = [
  ({ style }) => (
    <animated.img loading="lazy" src={webLandingLavado} style={style} />
  ),
  ({ style }) => <animated.img loading="lazy" src={mobile1} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={mobile2} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={mobile3} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={mobile4} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={web1} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={web2} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={web3} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={web4} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={web5} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={web6} style={style} />,
  ({ style }) => (
    <animated.img loading="lazy" src={LavadoPostesA} style={style} />
  ),
  ({ style }) => (
    <animated.img loading="lazy" src={LavadoPostesB} style={style} />
  ),
];

const imagesDLT = [
  ({ style }) => <animated.img loading="lazy" src={dlt1} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={dlt2} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={dlt3} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={dlt4} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={dlt5} style={style} />,
  // ({ style }) => <animated.img loading="lazy" src={DLTDeviceA} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={DLTTeamA} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={DLTTeamC} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={DLTTeamD} style={style} />,
];

const imagesSTS = [
  ({ style }) => (
    <animated.img loading="lazy" src={STSSoftwareA} style={style} />
  ),
  ({ style }) => (
    <animated.img loading="lazy" src={STSSoftwareB} style={style} />
  ),
  ({ style }) => (
    <animated.img loading="lazy" src={STSSoftwareC} style={style} />
  ),
  ({ style }) => (
    <animated.img loading="lazy" src={STSSoftwareD} style={style} />
  ),
  ({ style }) => <animated.img loading="lazy" src={STSDeviceA} style={style} />,
  ({ style }) => <animated.img loading="lazy" src={STSDeviceB} style={style} />,
];

const GalleryComponent = ({ type, size }) => {
  let propsSize = size.width;
  const [index, setIndex] = useState(0);
  const [context, setContext] = useContext(AppContext);
  const [left, setLeft] = useState(false);
  const onClickBefore = useCallback(() => setIndex((state) => state - 1), []);
  const onClickNext = useCallback(() => setIndex((state) => state + 1), []);

  const transitions = useTransition(index, (p) => p, {
    from: {
      opacity: 0,
      height: "100%",
      width: "auto",
      objectFit: "contain",
      borderRadius: "8px",
      transform:
        left === false
          ? "rotate(180deg) translateX(100%)"
          : "rotate(-90deg) translateX(100%)",
    },
    enter: {
      opacity: 1,
      transform: "rotate(0deg) translateX(0%)",
      height: "100%",
      width: "auto",
      objectFit: "contain",
      borderRadius: "8px",
    },
    leave: {
      opacity: 0,
      display: "none",
      height: "100%",
      width: "auto",
      objectFit: "contain",
      borderRadius: "8px",
      transform:
        left === false
          ? "rotate(-90deg) translateX(100%)"
          : "rotate(180deg) translateX(100%)",
    },
    config: config.stiff,
  });

  const handleModalTitle = (type) => {
    switch (type) {
      case 0:
        return "Sistema Avanzado de Poda";
      case 1:
        return "Sistema Avanzado de Lavado";
      case 2:
        return "Device Language Translator";
      case 3:
        return "Smart Tracking System";
      default:
        break;
    }
  };

  const getProjectImage = (type, item) => {
    switch (type) {
      case 0:
        return imagesPoda[item];
      case 1:
        return imagesLavado[item];
      case 2:
        return imagesDLT[item];
      case 3:
        return imagesSTS[item];
      default:
        break;
    }
  };

  const handleDisableNext = (type, index) => {
    switch (type) {
      case 0:
        if (index === imagesPoda.length - 1) {
          return true;
        } else {
          return false;
        }
      case 1:
        if (index === imagesLavado.length - 1) {
          return true;
        } else {
          return false;
        }
      case 2:
        if (index === imagesDLT.length - 1) {
          return true;
        } else {
          return false;
        }
      case 3:
        if (index === imagesSTS.length - 1) {
          return true;
        } else {
          return false;
        }

      default:
        if (index === imagesPoda.length - 1) {
          return true;
        } else {
          return false;
        }
        break;
    }
  };

  const handleChangeButtonBg = (type, index) => {
    switch (type) {
      case 0:
        if (index === imagesPoda.length - 1) {
          return "bg-gray-200 opacity-10";
        } else {
          return "bg-amarillo-alegria";
        }
      case 1:
        if (index === imagesLavado.length - 1) {
          return "bg-gray-200 opacity-10";
        } else {
          return "bg-amarillo-alegria";
        }
      case 2:
        if (index === imagesDLT.length - 1) {
          return "bg-gray-200 opacity-10";
        } else {
          return "bg-amarillo-alegria";
        }
      case 3:
        if (index === imagesSTS.length - 1) {
          return "bg-gray-200 opacity-10";
        } else {
          return "bg-amarillo-alegria";
        }
      default:
        if (index === imagesPoda.length - 1) {
          return "bg-gray-200 opacity-10";
        } else {
          return "bg-amarillo-alegria";
        }
    }
  };

  return (
    <div className="flex flex-col align-middle text-tinto justify-center w-full h-full bg-azul-marino overflow-hidden">
      <h2 className="z-10 rounded-xl text-xs md:text-xl font-Manu-Poppins top-10 absolute left-6 p-4 bg-amarillo-alegria">
        {handleModalTitle(type)}
      </h2>
      <button
        disabled={index === 0}
        className={`z-10 absolute rounded-full left-6 p-4 ${
          index === 0 && "text-gray-200"
        } ${
          index === 0 ? "bg-gray-200 opacity-10" : "bg-amarillo-alegria"
        } opacity-90 hover:opacity-100`}
        style={{ top: "50%" }}
        onClick={() => {
          onClickBefore();
          setLeft(true);
        }}
      >
        <img
          loading="lazy"
          src={LeftEmoji}
          style={{
            width: propsSize >= 767 ? 64 : propsSize <= 766 ? 20 : 36,
            height: propsSize >= 767 ? 64 : propsSize <= 766 ? 20 : 36,
          }}
        ></img>
      </button>
      <button
        disabled={handleDisableNext(type, index)}
        className={`z-10 absolute rounded-full right-6 p-4 ${handleChangeButtonBg(
          type,
          index
        )} opacity-90 hover:opacity-100`}
        style={{ top: "50%" }}
        onClick={() => {
          onClickNext();
          setLeft(false);
        }}
      >
        <img
          loading="lazy"
          src={RightEmoji}
          style={{
            width: propsSize >= 767 ? 64 : propsSize <= 766 ? 20 : 36,
            height: propsSize >= 767 ? 64 : propsSize <= 766 ? 20 : 36,
          }}
        ></img>
      </button>
      <button
        className={`z-10 absolute rounded-full top-10 right-6 p-4 bg-amarillo-alegria opacity-90 hover:opacity-100`}
        onClick={() => {
          setContext({
            ...context,
            imageModal: false,
          });
        }}
      >
        <img
          loading="lazy"
          src={CloseEmoji}
          style={{
            width: propsSize >= 767 ? 64 : propsSize <= 766 ? 20 : 36,
            height: propsSize >= 767 ? 64 : propsSize <= 766 ? 20 : 36,
          }}
        ></img>
      </button>

      {transitions.map(({ item, props, key }) => {
        const Image = getProjectImage(type, item);
        return <Image key={key} style={props} />;
      })}
    </div>
  );
};

export default memo(withSize()(GalleryComponent));
