import { useState, useEffect } from "react";
import Header from "./Header";
import Body from "./Body";
import PatronSVG from "../Img/patron.svg";
import PuntosSVG from "../Img/Puntos.svg";

import { AppContext } from "../Provider/config";
function App() {
  useEffect(() => {
    console.log(`
              __:.__
           (_:..'"=
            ::/ o o\          YAY!
           ;'-'   (_)      Someone is        .
           '-._  ;-'       Interested !   _'._|\/:
           .:;  ;                .         '- '   /_
          :.. ; ;,                \\      _/,    "_<
         :.|..| ;:                 \\__  '._____  _)
         :.|.'| ||                            _/ /
         :.|..| :'                         '';--:
         '.|..|:':       _              _ _ :|_\\:
      .. _:|__| '.\.''..' ) ___________ ( )_):|_|:
:....::''::/  | : :|''| "/ /_=_=_=_=_=/ :_[__'_\\_)`);
  }, []);
  const [context, setContext] = useState(null);
  return (
    <AppContext.Provider value={[context, setContext]}>
      <Header />
      <img
        loading="lazy"
        src={PuntosSVG}
        className="absolute right-4 mt-4 md:-mt-8 h-80"
      ></img>
      <img
        loading="lazy"
        src={PatronSVG}
        className="absolute pt-96 md:pt-0 mx-auto opacity-30"
      ></img>
      <Body />
    </AppContext.Provider>
  );
}

export default App;
