import { memo } from "react";
import WhoIAM from "./WhoIAM";
import MyWork from "./MyWork";
import ReactWorld from "./ReactWorld";
import ContactMe from "./ContactMe";

function Body() {
  return (
    <>
      <WhoIAM />
      <MyWork />
      <ReactWorld />
      <ContactMe />
    </>
  );
}

export default memo(Body);
