import { useRef, useEffect, useContext, memo, useState } from "react";
import { AppContext } from "../../Provider/config";
import MyPc from "../../Img/portatil.jpeg";
import Js from "../../Img/Tech/logo_js.svg";
import Node from "../../Img/Tech/logo_node.svg";
import ReactLogo from "../../Img/Tech/logo_react.svg";
import ReactLogoDummie from "../../Img/Tech/logo_react_dummie.svg";
import ReduxLogo from "../../Img/Tech/logo_redux.svg";

import Firebase from "../../Img/Tech/logo_firebase.svg";
import GoogleMaps from "../../Img/Tech/logo_maps.svg";
import Aws from "../../Img/Tech/logo_aws.svg";
import MaterialUI from "../../Img/Tech/logo_materialui.svg";

import ESLint from "../../Img/Tech/logo_eslint.svg";
import Netlify from "../../Img/Tech/logo_netlify.svg";

import ReactRouter from "../../Img/Tech/logo_reactrouter.svg";
import Webpack from "../../Img/Tech/logo_webpack.svg";
import { useSpring, animated } from "react-spring";

function ReactWorld() {
  const { rotateA } = useSpring({
    from: {
      rotateA: 0,
    },
    to: async (next) => {
      while (1) {
        await next({ rotateA: -360 });
        await next({ rotateA: 360 });
      }
    },
    config: {
      tension: 400,
      friction: 130,
    },
    reset: true,
  });
  const { rotateB } = useSpring({
    from: {
      rotateB: 0,
    },
    to: async (next) => {
      while (1) {
        await next({ rotateB: 360 });
        await next({ rotateB: -360 });
      }
    },
    reset: true,
    config: {
      tension: 20,
      friction: 6,
    },
  });

  return (
    <div className="mt-40">
      <h1 className="font-sans text-3xl md:text-5xl my-8 text-center font-bold">
        React 🌎
      </h1>
      <div className="w-full h-container-react rounded-full shadow-lg bg-gradient-to-b from-crema-bajo to-azul-marino">
        <img
          loading="lazy"
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          src={MyPc}
          className="w-60 h-60 md:w-96 md:h-96 rounded-full opacity-40"
        />
        <div className="flex justify-around flex-wrap">
          <div className="flex flex-col justify-center transform items-center hover:scale-125 motion-reduce:transform-none">
            <img loading="lazy" src={Js} />
            <label className="text-lg font-Manu-Poppins">Javascript</label>
          </div>
          <div className="flex flex-col justify-center transform items-center hover:scale-125 motion-reduce:transform-none">
            <img loading="lazy" src={Node} />
            <label className="text-lg font-Manu-Poppins">Node Js</label>
          </div>
        </div>
        <div className="flex justify-center flex-wrap my-16">
          <div className="flex justify-center flex-col items-center transform scale-100 hover:scale-125 motion-reduce:transform-none">
            <animated.img
              loading="lazy"
              style={{
                transform: rotateA.interpolate(
                  (z) =>
                    `rotateZ(${z}deg) rotateX(${
                      z * 77 + (z / 10) * z
                    }deg) rotateY(${(z * z - z * 0.444) / 80}deg)`
                ),
                marginLeft: "100px",
              }}
              className="h-32 w-32"
              src={ReactLogo}
            />
            <img
              loading="lazy"
              style={{
                position: "absolute",
                top: 0,
                marginLeft: "100px",
              }}
              className="h-32 w-32"
              src={ReactLogoDummie}
            />
            <animated.img
              loading="lazy"
              style={{
                transform: rotateB.interpolate((z) => `rotateZ(${z}deg)`),
                marginRight: "100px",
              }}
              className="h-32 w-32"
              src={ReduxLogo}
            />
            <div className="flex flex-col justify-center items-center pt-6">
              <label className="text-lg font-Manu-Poppins">React + Redux</label>
              <label className="font-Manu-Poppins text-sm">
                State & Stateless Components
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-around flex-wrap my-0 mx-48">
          <div className="flex flex-col justify-center my-8 transform items-center hover:scale-125 motion-reduce:transform-none">
            <img loading="lazy" src={Firebase} />
            <label className="text-lg font-Manu-Poppins">Firebase</label>
          </div>
          <div className="flex flex-col justify-center my-8 transform items-center hover:scale-125 motion-reduce:transform-none">
            <img loading="lazy" src={GoogleMaps} />
            <label className="text-lg font-Manu-Poppins">Google Maps</label>
          </div>
          <div className="flex flex-col justify-center my-8 transform items-center hover:scale-125 motion-reduce:transform-none">
            <img loading="lazy" src={Aws} />
            <label className="text-lg font-Manu-Poppins">
              Amazon Web Services
            </label>
          </div>
          <div className="flex flex-col justify-center my-4 sm:my-0 transform items-center hover:scale-125 motion-reduce:transform-none">
            <img loading="lazy" src={MaterialUI} />
            <label className="text-lg font-Manu-Poppins">Material UI</label>
          </div>
        </div>
        <div className="flex justify-around flex-wrap mb-18 my-0 sm:my-18 mx-0 sm:mx-36">
          <div className="flex flex-col justify-center transform items-center hover:scale-125 motion-reduce:transform-none">
            <img loading="lazy" src={ESLint} />
            <label className="text-lg font-Manu-Poppins">ESLint</label>
          </div>
          <div className="flex flex-col justify-center transform items-center hover:scale-125 motion-reduce:transform-none">
            <img loading="lazy" src={Netlify} />
            <label className="text-lg font-Manu-Poppins">Netlify</label>
          </div>
        </div>
        <div className="flex justify-evenly flex-wrap md:my-24">
          <div className="flex flex-col justify-center transform items-center hover:scale-125 motion-reduce:transform-none">
            <img loading="lazy" src={ReactRouter} />
            <label className="text-lg font-Manu-Poppins">React Router</label>
          </div>
          <div className="flex flex-col justify-center transform items-center hover:scale-125 motion-reduce:transform-none">
            <img loading="lazy" src={Webpack} />
            <label className="text-lg font-Manu-Poppins">Webpack</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ReactWorld);
