import { useEffect, useState, useContext } from "react";
import { useSpring, animated } from "react-spring";
import { useGesture } from "react-with-gesture";

import LangLogo from "../Img/language_logo.svg";
import SwipeLeftEmoji from "../Img/SwipeLeftEmoji.svg";

import { AppContext } from "../Provider/config";

export default function Idioma() {
  const [value, setValue] = useState(undefined);
  const [languaje, setLanguaje] = useState(undefined);
  const [initInterpolation, setInitInterpolation] = useState(false);
  const [_, setContext] = useContext(AppContext);
  const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }));

  const [ab, setSVG] = useSpring(() => ({
    xy: [0, 0],
    config: {
      mass: 12.4,
      tension: 210,
      friction: 9,
      velocity: 9,
    },
  }));

  const transC = (x, y) => `translate3d(${x / 220}px,${y / 220}px,0)`;

  useEffect(() => {
    if (value === true) {
      setLanguaje((v) => !v);
      setInitInterpolation(false);
    }
    setContext((c) => {
      return { ...c, language: languaje };
    });
  }, [value]);

  const calc = (x, y) => [
    x - window.innerWidth / 15,
    y - window.innerHeight / 2,
  ];

  const bind = useGesture(({ down, delta }) => {
    setValue(down);
    set({
      xy: down ? delta : [0, 0],
      config: {
        mass: 1.8,
        tension: 180,
        friction: 9,
      },
    });
  });

  useEffect(() => {
    setSVG({ xy: calc(Math.random() * 30, Math.random() * 30) });
    setInitInterpolation(true);
  }, []);

  return (
    <div>
      <div style={{ position: "absolute" }}>
        <svg
          width="64"
          height="42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <animated.path
            d="M23.68 37.1L8.96 21L23.68 4.9L19.2 0L0 21L19.2 42L23.68 37.1ZM40.32 37.1L55.04 21L40.32 4.9L44.8 0L64 21L44.8 42L40.32 37.1Z"
            fill="#372E2E"
          />
        </svg>
      </div>
      <div
        style={{
          transform: "translate(18px, 8px)",
        }}
        // onMouseMove={({ clientX: a, clientY: b }) => setSVG({ xy: calc(a, b) })}
        className="select-none"
      >
        <animated.div
          {...bind()}
          className="font-Manu-Poppins"
          style={{
            width: "28px",
            height: "28px",
            background: "#FFF76A",
            borderRadius: "50%",
            cursor: "-webkit-grab",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#372E2E",
            whiteSpace: "pre",
            fontSize: 20,
            transform:
              initInterpolation === true && languaje === undefined
                ? ab.xy.interpolate(transC)
                : xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`),
          }}
        >
          {languaje === undefined ? (
            <img src={LangLogo} />
          ) : languaje === true ? (
            "EN"
          ) : (
            languaje === false && "ES"
          )}
        </animated.div>
        <img src={SwipeLeftEmoji} className="w-8 h-8 text-tinto opacity-60" />
      </div>
    </div>
  );
}
