import { useState, useRef, useLayoutEffect, useContext, memo } from "react";
import { withSize } from "react-sizeme";
import { animated, useTransition, useSpring } from "react-spring";
import { AppContext } from "../../Provider/config";

import GalleryComponent from "./GalleryComponent";

import PodaPhotoA from "../../Img/Poda/poda1.jpg";
import PodaPhotoB from "../../Img/Poda/subestacion.jpg";
import LavadoPostesA from "../../Img/Lavado/LavadoPostesA.jpg";
import LavadoPostesB from "../../Img/Lavado/LavadoPostesB.jpg";
import DLTTeamD from "../../Img/DLT/DLTTeamD.jpg";
import DLTTeamA from "../../Img/DLT/DLTTeamA.jpg";
import STSDeviceA from "../../Img/STS/STSDeviceA.jpg";
import STSDeviceB from "../../Img/STS/STSDeviceB.jpg";
import Aire from "../../Img/aire.png";
import Afinia from "../../Img/afinia.png";
import Electricaribe from "../../Img/electricaribe.jpg";
import EmojiArbol from "../../Img/EmojiArbol.svg";
import LupaEmoji from "../../Img/LupaEmoji.svg";
import CheckEmoji from "../../Img/CheckEmoji.svg";
import BackEmoji from "../../Img/BackEmoji.svg";
import EmojiPoste from "../../Img/EmojiPoste.svg";
import EmojiDLT from "../../Img/EmojiDLT.svg";
import EmojiSTS from "../../Img/EmojiSTS.svg";
import ClockSVG from "../../Img/ClockSVG.svg";
import ServiceSVG from "../../Img/ServiceSVG.svg";

import {
  SSCPodaResume,
  SSCLavadoResume,
  SSCDLTResume,
  SSCSTSResume,
  IssueTitle,
  JobTitle,
  SolutionTitle,
  ClientsTitle,
  PodaIssueTitle,
  PodaIssueA,
  PodaIssueB,
  PodaIssueC,
  LavadoIssueTitle,
  LavadoIssueA,
  LavadoIssueB,
  LavadoIssueC,
  DLTIssueTitle,
  DLTIssueA,
  DLTIssueB,
  DLTIssueC,
  STSIssueTitle,
  STSIssueA,
  STSIssueB,
  STSIssueC,
  PodaSolutionTitle,
  PodaSolutionA,
  PodaSolutionB,
  PodaSolutionC,
  LavadoSolutionTitle,
  LavadoSolutionA,
  LavadoSolutionB,
  LavadoSolutionC,
  DLTSolutionTitle,
  DLTSolutionA,
  DLTSolutionB,
  DLTSolutionC,
  STSSolutionTitle,
  STSSolutionA,
  STSSolutionB,
  STSSolutionC,
  PodaDuration,
  PodaType,
  LavadoDuration,
  LavadoType,
  DLTDuration,
  DLTType,
  STSDuration,
  STSType,
  galleryButton,
  lookSolution,
  developmentTitle,
} from "../../languages.json";

function MyWork({ size }) {
  let propsSize = size.width;
  const refMyWork = useRef();
  const refInWork = useRef();
  const refOutWork = useRef();
  const refInSolution = useRef();
  const [projectList, setProjectList] = useState(0);
  const [projectInfo, setProjectInfo] = useState(false);
  const [readingSolution, setReadingSolution] = useState(false);
  const [solution, setSolution] = useState(false);
  const [context, setContext] = useContext(AppContext);
  const [showWork, setShowWork] = useState(null);
  let languageContext = context && context.language && context.language;

  const handleWorkScroll = () => {
    let offsetTop1 = refInWork.current.getBoundingClientRect().top - 350;
    let offsetTop2 = refOutWork.current.getBoundingClientRect().top - 350;

    if (offsetTop1 <= 0 && offsetTop2 >= -20) {
      setShowWork(true);
    } else {
      setShowWork(false);
    }
  };

  const handleFirstPhoto = (data) => {
    switch (data) {
      case 0:
        return PodaPhotoA;
      case 1:
        return LavadoPostesA;
      case 2:
        return DLTTeamA;
      case 3:
        return STSDeviceA;
    }
  };

  const handleSecondPhoto = (data) => {
    switch (data) {
      case 0:
        return PodaPhotoB;
      case 1:
        return LavadoPostesB;
      case 2:
        return DLTTeamD;
      case 3:
        return STSDeviceB;
    }
  };

  const [y, setY, stop] = useSpring(() => ({
    immediate: false,
    y: 0,
    onFrame: (props) => {
      window.scroll(0, props.y);
    },
  }));

  useLayoutEffect(() => {
    let scrollMyWork = refMyWork.current.getBoundingClientRect().top;
    let scrollMySolution = refInSolution.current.getBoundingClientRect().top;
    let scrollOutOfWork = refOutWork.current.getBoundingClientRect().top + 300;
    setContext({
      ...context,
      scrollMyWork,
      scrollMySolution,
      scrollOutOfWork,
    });
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleWorkScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleWorkScroll);
    };
  });

  const onLanguageChange = (type) => {
    let spanishLanguage =
      languageContext === undefined || languageContext === false;
    switch (type) {
      case "ssc-poda-resume":
        return spanishLanguage ? SSCPodaResume["es"] : SSCPodaResume["en"];
      case "ssc-lavado-resume":
        return spanishLanguage ? SSCLavadoResume["es"] : SSCLavadoResume["en"];
      case "ssc-dlt-resume":
        return spanishLanguage ? SSCDLTResume["es"] : SSCDLTResume["en"];
      case "ssc-sts-resume":
        return spanishLanguage ? SSCSTSResume["es"] : SSCSTSResume["en"];
      case "issue-title":
        return spanishLanguage ? IssueTitle["es"] : IssueTitle["en"];
      case "job-title":
        return spanishLanguage ? JobTitle["es"] : JobTitle["en"];
      case "solution-title":
        return spanishLanguage ? SolutionTitle["es"] : SolutionTitle["en"];
      case "clients-title":
        return spanishLanguage ? ClientsTitle["es"] : ClientsTitle["en"];
      case "poda-issue-title":
        return spanishLanguage ? PodaIssueTitle["es"] : PodaIssueTitle["en"];
      case "poda-issue-a":
        return spanishLanguage ? PodaIssueA["es"] : PodaIssueA["en"];
      case "poda-issue-b":
        return spanishLanguage ? PodaIssueB["es"] : PodaIssueB["en"];
      case "poda-issue-c":
        return spanishLanguage ? PodaIssueC["es"] : PodaIssueC["en"];
      case "lavado-issue-title":
        return spanishLanguage
          ? LavadoIssueTitle["es"]
          : LavadoIssueTitle["en"];
      case "lavado-issue-a":
        return spanishLanguage ? LavadoIssueA["es"] : LavadoIssueA["en"];
      case "lavado-issue-b":
        return spanishLanguage ? LavadoIssueB["es"] : LavadoIssueB["en"];
      case "lavado-issue-c":
        return spanishLanguage ? LavadoIssueC["es"] : LavadoIssueC["en"];
      case "dlt-issue-title":
        return spanishLanguage ? DLTIssueTitle["es"] : DLTIssueTitle["en"];
      case "dlt-issue-a":
        return spanishLanguage ? DLTIssueA["es"] : DLTIssueA["en"];
      case "dlt-issue-b":
        return spanishLanguage ? DLTIssueB["es"] : DLTIssueB["en"];
      case "dlt-issue-c":
        return spanishLanguage ? DLTIssueC["es"] : DLTIssueC["en"];
      case "sts-issue-title":
        return spanishLanguage ? STSIssueTitle["es"] : STSIssueTitle["en"];
      case "sts-issue-a":
        return spanishLanguage ? STSIssueA["es"] : STSIssueA["en"];
      case "sts-issue-b":
        return spanishLanguage ? STSIssueB["es"] : STSIssueB["en"];
      case "sts-issue-c":
        return spanishLanguage ? STSIssueC["es"] : STSIssueC["en"];
      case "poda-solution-title":
        return spanishLanguage
          ? PodaSolutionTitle["es"]
          : PodaSolutionTitle["en"];
      case "poda-solution-a":
        return spanishLanguage ? PodaSolutionA["es"] : PodaSolutionA["en"];
      case "poda-solution-b":
        return spanishLanguage ? PodaSolutionB["es"] : PodaSolutionB["en"];
      case "poda-solution-c":
        return spanishLanguage ? PodaSolutionC["es"] : PodaSolutionC["en"];
      case "lavado-solution-title":
        return spanishLanguage
          ? LavadoSolutionTitle["es"]
          : LavadoSolutionTitle["en"];
      case "lavado-solution-a":
        return spanishLanguage ? LavadoSolutionA["es"] : LavadoSolutionA["en"];
      case "lavado-solution-b":
        return spanishLanguage ? LavadoSolutionB["es"] : LavadoSolutionB["en"];
      case "lavado-solution-c":
        return spanishLanguage ? LavadoSolutionC["es"] : LavadoSolutionC["en"];
      case "dlt-solution-title":
        return spanishLanguage
          ? DLTSolutionTitle["es"]
          : DLTSolutionTitle["en"];
      case "dlt-solution-a":
        return spanishLanguage ? DLTSolutionA["es"] : DLTSolutionA["en"];
      case "dlt-solution-b":
        return spanishLanguage ? DLTSolutionB["es"] : DLTSolutionB["en"];
      case "dlt-solution-c":
        return spanishLanguage ? DLTSolutionC["es"] : DLTSolutionC["en"];
      case "sts-solution-title":
        return spanishLanguage
          ? STSSolutionTitle["es"]
          : STSSolutionTitle["en"];
      case "sts-solution-a":
        return spanishLanguage ? STSSolutionA["es"] : STSSolutionA["en"];
      case "sts-solution-b":
        return spanishLanguage ? STSSolutionB["es"] : STSSolutionB["en"];
      case "sts-solution-c":
        return spanishLanguage ? STSSolutionC["es"] : STSSolutionC["en"];
      case "poda-duration":
        return spanishLanguage ? PodaDuration["es"] : PodaDuration["en"];
      case "poda-type":
        return spanishLanguage ? PodaType["es"] : PodaType["en"];
      case "lavado-duration":
        return spanishLanguage ? LavadoDuration["es"] : LavadoDuration["en"];
      case "lavado-type":
        return spanishLanguage ? LavadoType["es"] : LavadoType["en"];
      case "dlt-duration":
        return spanishLanguage ? DLTDuration["es"] : DLTDuration["en"];
      case "dlt-type":
        return spanishLanguage ? DLTType["es"] : DLTType["en"];
      case "sts-duration":
        return spanishLanguage ? STSDuration["es"] : STSDuration["en"];
      case "sts-type":
        return spanishLanguage ? STSType["es"] : STSType["en"];
      case "gallery-button":
        return spanishLanguage ? galleryButton["es"] : galleryButton["en"];
      case "look-solution":
        return spanishLanguage ? lookSolution["es"] : lookSolution["en"];
      case "development-title":
        return spanishLanguage
          ? developmentTitle["es"]
          : developmentTitle["en"];
      default:
        break;
    }
  };

  const infoProjectArray = [
    {
      title: "SmartSeals Poda",
      text: onLanguageChange("ssc-poda-resume"),
    },
    {
      title: "SmartSeals Lavado",
      text: onLanguageChange("ssc-lavado-resume"),
    },
    {
      title: "SmartSeals DLT",
      text: onLanguageChange("ssc-dlt-resume"),
    },
    {
      title: "SmartSeals STS",
      text: onLanguageChange("ssc-sts-resume"),
    },
  ];
  const issueProjectArray = [
    {
      title: onLanguageChange("poda-issue-title"),
      textA: onLanguageChange("poda-issue-a"),
      textB: onLanguageChange("poda-issue-b"),
      textC: onLanguageChange("poda-issue-c"),
    },
    {
      title: onLanguageChange("lavado-issue-title"),
      textA: onLanguageChange("lavado-issue-a"),
      textB: onLanguageChange("lavado-issue-b"),
      textC: onLanguageChange("lavado-issue-c"),
    },
    {
      title: onLanguageChange("dlt-issue-title"),
      textA: onLanguageChange("dlt-issue-a"),
      textB: onLanguageChange("dlt-issue-b"),
      textC: onLanguageChange("dlt-issue-c"),
    },
    {
      title: onLanguageChange("sts-issue-title"),
      textA: onLanguageChange("sts-issue-a"),
      textB: onLanguageChange("sts-issue-b"),
      textC: onLanguageChange("sts-issue-c"),
    },
  ];
  const solutionProjectArray = [
    {
      title: onLanguageChange("poda-solution-title"),
      textA: onLanguageChange("poda-solution-a"),
      textB: onLanguageChange("poda-solution-b"),
      textC: onLanguageChange("poda-solution-c"),
    },
    {
      title: onLanguageChange("lavado-solution-title"),
      textA: onLanguageChange("lavado-solution-a"),
      textB: onLanguageChange("lavado-solution-b"),
      textC: onLanguageChange("lavado-solution-c"),
    },
    {
      title: onLanguageChange("dlt-solution-title"),
      textA: onLanguageChange("dlt-solution-a"),
      textB: onLanguageChange("dlt-solution-b"),
      textC: onLanguageChange("dlt-solution-c"),
    },
    {
      title: onLanguageChange("sts-solution-title"),
      textA: onLanguageChange("sts-solution-a"),
      textB: onLanguageChange("sts-solution-b"),
      textC: onLanguageChange("sts-solution-c"),
    },
  ];
  const projectCategory = [
    {
      textA: onLanguageChange("poda-duration"),
      textB: onLanguageChange("poda-type"),
    },
    {
      textA: onLanguageChange("lavado-duration"),
      textB: onLanguageChange("lavado-type"),
    },
    {
      textA: onLanguageChange("dlt-duration"),
      textB: onLanguageChange("dlt-type"),
    },
    {
      textA: onLanguageChange("sts-duration"),
      textB: onLanguageChange("sts-type"),
    },
  ];
  let data = useTransition(projectList, (p) => p, {
    from: { opacity: 0, display: "block" },
    enter: { opacity: 1, display: "block" },
    leave: { display: "none" },
  });
  return (
    <>
      {context.imageModal === true && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <GalleryComponent type={projectList} />
        </div>
      )}
      <div className="flex flex-col justify-center text-tinto lg:justify-around bg-azul-marino mt-24 py-24">
        <div ref={refInSolution}></div>
        {showWork === true && (
          <div
            style={{ top: "25%", zIndex: 4, left: "-5px" }}
            className="fixed flex flex-col justify-center"
          >
            <section className="text-center">
              <button
                style={{
                  backgroundColor: scroll >= 180 && "#FFF76A",
                  borderRadius: scroll >= 180 && "50%",
                  margin: scroll >= 180 && "4px 12px",
                  opacity: projectList === 0 && 1,
                }}
                className="flex h-auto md:text-xl opacity-60 bg-amarillo-alegria hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none focus:opacity-100 hover:opacity-100"
                onClick={() => {
                  setProjectList(0);
                  setSolution(false);
                  setProjectInfo(false);
                }}
              >
                <img
                  loading="lazy"
                  style={{
                    width: propsSize >= 767 ? 64 : propsSize <= 766 ? 30 : 36,
                    height: propsSize >= 767 ? 64 : propsSize <= 766 ? 30 : 36,
                  }}
                  src={EmojiArbol}
                />
              </button>
              <label className="text-xs font-Manu-Poppins">Poda</label>
            </section>
            <section className="text-center">
              <button
                style={{
                  backgroundColor: scroll >= 180 && "#FFF76A",
                  borderRadius: scroll >= 180 && "50%",
                  margin: scroll >= 180 && "4px 12px",
                  opacity: projectList === 1 && 1,
                }}
                className="flex h-auto md:text-xl opacity-60 bg-amarillo-alegria hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none focus:opacity-100 hover:opacity-100"
                onClick={() => {
                  setProjectList(1);
                  setSolution(false);
                  setProjectInfo(false);
                }}
              >
                <img
                  loading="lazy"
                  style={{
                    width: propsSize >= 767 ? 64 : propsSize <= 766 ? 30 : 36,
                    height: propsSize >= 767 ? 64 : propsSize <= 766 ? 30 : 36,
                  }}
                  src={EmojiPoste}
                />
              </button>
              <label className="text-xs font-Manu-Poppins">Lavado</label>
            </section>
            <section className="text-center">
              <button
                style={{
                  backgroundColor: scroll >= 180 && "#FFF76A",
                  borderRadius: scroll >= 180 && "50%",
                  margin: scroll >= 180 && "4px 12px",
                  opacity: projectList === 2 && 1,
                }}
                onClick={() => {
                  setProjectList(2);
                  setSolution(false);
                  setProjectInfo(false);
                }}
                className="flex h-auto md:text-xl opacity-60 bg-amarillo-alegria hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none focus:opacity-100 hover:opacity-100"
              >
                <img
                  loading="lazy"
                  style={{
                    width: propsSize >= 767 ? 64 : propsSize <= 766 ? 30 : 36,
                    height: propsSize >= 767 ? 64 : propsSize <= 766 ? 30 : 36,
                  }}
                  src={EmojiDLT}
                />
              </button>
              <label className="text-xs font-Manu-Poppins">SSC DLT</label>
            </section>
            <section className="text-center">
              <button
                style={{
                  backgroundColor: scroll >= 180 && "#FFF76A",
                  borderRadius: scroll >= 180 && "50%",
                  margin: scroll >= 180 && "4px 12px",
                  opacity: projectList === 3 && 1,
                }}
                onClick={() => {
                  setProjectList(3);
                  setSolution(false);
                  setProjectInfo(false);
                }}
                className="flex h-auto md:text-xl opacity-60 bg-amarillo-alegria hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none focus:opacity-100 hover:opacity-100"
              >
                <img
                  loading="lazy"
                  style={{
                    width: propsSize >= 767 ? 64 : propsSize <= 766 ? 30 : 36,
                    height: propsSize >= 767 ? 64 : propsSize <= 766 ? 30 : 36,
                  }}
                  src={EmojiSTS}
                />
              </button>
              <label className="text-xs font-Manu-Poppins">SSC STS</label>
            </section>
          </div>
        )}
        <h1 className="text-center font-sans text-4xl md:text-5xl font-bold pb-12">
          <div ref={refMyWork}></div>
          <div ref={refInWork}></div>
          {solution === false &&
            projectInfo === true &&
            onLanguageChange("issue-title")}
          {solution === true &&
            projectInfo === false &&
            readingSolution === false &&
            onLanguageChange("job-title")}
          {solution === true &&
            projectInfo === false &&
            readingSolution === true &&
            onLanguageChange("solution-title")}
          {solution === false &&
            projectInfo === false &&
            onLanguageChange("job-title")}
          {solution === true &&
            projectInfo === true &&
            onLanguageChange("solution-title")}
        </h1>
        {solution === true &&
          data.map(({ props, key }) => {
            return (
              <div
                key={key}
                className="flex justify-center flex-row flex-wrap mx-8 md:mx-30"
              >
                <section
                  style={{
                    backgroundColor: "#bbdfc8",
                    zIndex: 3,
                    display: "flex",
                    width: "80%",
                    justifyContent: "center",
                    margin: size.width <= 1770 && "auto 0",
                  }}
                  className="flex flex-row flex-wrap text-lg md:text-2xl shadow-xl lg:text-3xl  font-Manu-Poppins p-4 bg-azul-marino rounded-xl my-12 w-80 md:w-96 h-auto"
                >
                  <animated.div key={key} style={props}>
                    <animated.h2
                      style={props}
                      className="font-sans text-xl sm:text-4xl font-bold text-center"
                    >
                      <div className="inline">
                        {solutionProjectArray[projectList].title}
                        <button
                          className="absolute h-auto md:text-xl opacity-80 bg-amarillo-alegria hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none focus:opacity-100 hover:opacity-100"
                          onClick={() => {
                            setReadingSolution(false);
                            setSolution(false);
                          }}
                        >
                          <img
                            loading="lazy"
                            style={{
                              width: propsSize >= 640 ? 22 : 16,
                              height: propsSize >= 640 ? 22 : 16,
                            }}
                            src={BackEmoji}
                          />
                        </button>
                      </div>
                    </animated.h2>
                    <ul className="w-auto block md:flex mt-2 text-base sm:text-xl md:text-2xl sm:mt-3 font-Manu-Poppins text-center md:text-left">
                      <li>
                        <img
                          src={CheckEmoji}
                          className="w-8 h-8 my-auto -ml-2 inline"
                        />
                        {solutionProjectArray[projectList].textA}
                      </li>
                      <br></br>
                      <li>
                        <img
                          src={CheckEmoji}
                          className="w-8 h-8 my-auto -ml-2 inline"
                        />
                        {solutionProjectArray[projectList].textB}
                      </li>
                      <br></br>
                      <li>
                        <img
                          src={CheckEmoji}
                          className="w-8 h-8 my-auto -ml-2 inline"
                        />
                        {solutionProjectArray[projectList].textC}
                      </li>
                    </ul>
                  </animated.div>
                  <animated.div
                    style={props}
                    className="flex justify-center text-xl mt-2 sm:text-2xl font-Manu-Poppins text-center font-bold"
                  >
                    <div className="flex flex-col justify-center font-normal text-lg">
                      <button
                        onClick={() => {
                          setContext({
                            ...context,
                            imageModal: true,
                          });
                        }}
                        className="flex text-tinto justify-center text-center my-2 h-auto md:text-xl opacity-100 bg-amarillo-alegria font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none "
                      >
                        {onLanguageChange("gallery-button")}
                      </button>
                    </div>
                  </animated.div>
                </section>
              </div>
            );
          })}
        {projectInfo === true &&
          data.map(({ props, key }) => {
            return (
              <div
                key={key}
                className="flex justify-center flex-row flex-wrap mx-8 md:mx-30"
              >
                <section
                  style={{
                    backgroundColor: "#bbdfc8",
                    zIndex: 3,
                    display: "flex",
                    width: "80%",

                    justifyContent: "center",
                    margin: size.width <= 1770 && "auto 0",
                  }}
                  className="flex flex-row flex-wrap text-lg md:text-2xl shadow-xl lg:text-3xl  font-Manu-Poppins p-4 bg-azul-marino rounded-xl my-12 w-80 md:w-96 h-auto"
                >
                  <animated.div key={key} style={props}>
                    <animated.h2
                      style={props}
                      className="font-sans text-xl sm:text-4xl font-bold text-center"
                    >
                      <div>{issueProjectArray[projectList].title}</div>
                    </animated.h2>
                    <ul className="w-auto block md:flex mt-2 text-base sm:text-xl md:text-2xl sm:mt-3 font-Manu-Poppins text-center md:text-left">
                      <li>
                        <img
                          src={CheckEmoji}
                          className="w-8 h-8 my-auto -ml-2 inline"
                        />
                        {issueProjectArray[projectList].textA}
                      </li>
                      <br></br>
                      <li>
                        <img
                          src={CheckEmoji}
                          className="w-8 h-8 my-auto -ml-2 inline"
                        />
                        {issueProjectArray[projectList].textB}
                      </li>
                      <br></br>
                      <li>
                        <img
                          src={CheckEmoji}
                          className="w-8 h-8 my-auto -ml-2 inline"
                        />
                        {issueProjectArray[projectList].textC}
                      </li>
                    </ul>
                  </animated.div>
                  <animated.div
                    style={props}
                    className="flex text-tinto justify-center text-xl mt-2 sm:text-2xl font-Manu-Poppins text-center font-bold"
                  >
                    {onLanguageChange("development-title")}
                    <div className="flex flex-col justify-center font-normal text-lg">
                      <label className="text-tinto flex justify-center ">
                        <img
                          loading="lazy"
                          src={ClockSVG}
                          className="w-5 h-5 my-auto mx-1"
                        />
                        {projectCategory[projectList].textA}
                      </label>
                      <label className=" text-tinto flex justify-center">
                        <img
                          loading="lazy"
                          src={ServiceSVG}
                          className="w-5 h-5 my-auto mx-1 text-tinto"
                        />
                        {projectCategory[projectList].textB}
                      </label>
                      <button
                        onClick={() => {
                          setY({ y: context.scrollMyWork });
                          setProjectInfo(false);
                          setSolution(true);
                          setReadingSolution(true);
                        }}
                        className="flex text-tinto justify-center text-center my-2 h-auto md:text-xl opacity-100 bg-amarillo-alegria font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none "
                      >
                        {onLanguageChange("look-solution")}
                      </button>
                    </div>
                  </animated.div>
                </section>
              </div>
            );
          })}
        {solution === false &&
          data.map(({ props, key }) => {
            return (
              <div
                key={key}
                style={{ display: projectInfo === true ? "none" : "flex" }}
                className="flex justify-center flex-row flex-wrap mx-16 md:mx-30"
              >
                <section
                  style={{
                    backgroundColor: "#bbdfc8",
                    zIndex: 3,
                    display: "flex",
                    justifyContent: "center",
                    margin: size.width <= 1770 && "auto 0",
                  }}
                  className="flex flex-col text-lg md:text-2xl lg:text-3xl shadow-xl font-Manu-Poppins p-4 bg-azul-marino rounded-xl my-12 w-80 md:w-96 h-auto"
                >
                  <animated.div key={key} style={props}>
                    <animated.h2
                      style={props}
                      className="font-sans text-xl sm:text-4xl font-bold text-center"
                    >
                      <div>
                        {infoProjectArray[projectList].title}
                        <button
                          style={{
                            transform:
                              propsSize >= 848
                                ? "translate(10px, -30px)"
                                : "translate(-10px, -5px)",
                          }}
                          className="absolute h-auto md:text-xl opacity-80 bg-amarillo-alegria hover:text-white font-Manu-Poppins mx-4 p-2 rounded-full focus:outline-none focus:opacity-100 hover:opacity-100"
                          onClick={() => setProjectInfo(true)}
                        >
                          <img
                            loading="lazy"
                            style={{
                              width: propsSize >= 640 ? 32 : 20,
                              height: propsSize >= 640 ? 32 : 20,
                            }}
                            src={LupaEmoji}
                          />
                        </button>
                      </div>
                    </animated.h2>
                    <p className="w-auto mt-2 text-base sm:text-xl md:text-2xl sm:mt-3 font-Manu-Poppins text-center">
                      {infoProjectArray[projectList].text}
                    </p>
                  </animated.div>
                  <animated.label
                    style={props}
                    className="text-xl text-tinto mt-2 sm:text-2xl font-Manu-Poppins text-center font-bold"
                  >
                    {onLanguageChange("clients-title")}
                    <div className="flex justify-center">
                      {projectList === 3 ? (
                        <a
                          className="w-20 h-28"
                          href="https://electricaribeliquidacion.com/liquidacion/"
                          target="_blank"
                        >
                          <img
                            loading="lazy"
                            className="rounded-3xl"
                            src={Electricaribe}
                          />
                        </a>
                      ) : (
                        <>
                          {" "}
                          <a href="https://www.air-e.com/" target="_blank">
                            <img loading="lazy" src={Aire} />
                          </a>
                          <a
                            href="https://energiacaribemar.co/"
                            target="_blank"
                          >
                            <img loading="lazy" src={Afinia} />
                          </a>
                        </>
                      )}
                    </div>
                  </animated.label>
                </section>
                <animated.section className="flex pt-1 -mx-16 md:mx-auto my-4 justify-center flex-col lg:flex-row flex-wrap">
                  <div className="flex flex-wrap flex-col lg:flex-row justify-center 2xl:justify-between m-auto">
                    <animated.img
                      loading="lazy"
                      src={handleFirstPhoto(projectList)}
                      style={props}
                      className="flex p-1 object-cover rounded-xl h-36 w-48 sm:h-52 sm:w-72 lg:h-img-work lg:w-img-work shadow-md"
                      // width={
                      //   propsSize <= 1000 ? 800 : propsSize <= 1740 ? 740 : 600
                      // }
                    />
                    <animated.img
                      loading="lazy"
                      src={handleSecondPhoto(projectList)}
                      style={props}
                      className="flex p-1 object-cover rounded-xl h-36 w-48 sm:h-52 sm:w-72 lg:h-img-work lg:w-img-work shadow-md"
                      // width={
                      //   propsSize <= 1000 ? 800 : propsSize <= 1740 ? 760 : 600
                      // }
                    />
                  </div>
                </animated.section>
              </div>
            );
          })}
        <div style={{ display: "hidden" }} ref={refOutWork}></div>
      </div>
    </>
  );
}

export default memo(withSize()(MyWork));
