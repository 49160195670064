import { useState, useContext } from "react";
import { useSpring, animated } from "react-spring";
import { useFormspark } from "@formspark/use-formspark";
import { AppContext } from "../../Provider/config";

import twitter from "../../Img/twitter.svg";
import instagram from "../../Img/instagram.svg";
import GithubLogo from "../../Img/GithubLogo.svg";
import linkedin from "../../Img/linkedin.svg";

import LocalizacionLogo from "../../Img/LocalizacionLogo.svg";
import LlamadaLogo from "../../Img/LlamadaLogo.svg";
import EmailLogo from "../../Img/EmailLogo.svg";

import {
  FooterTitleA,
  FooterTitleB,
  FooterTitleLeft,
  FooterFieldName,
  FooterFieldEmail,
  FooterFieldTitle,
  FooterFieldMessage,
  FooterSend,
  FooterNeighbourhood,
  FooterEmail,
  FooterFormValidation,
  FooterFormSuccess,
} from "../../languages.json";

const FORMSPARK_FORM_ID = "zCXZAs0s";

function ContactMe() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [submited, setSubmited] = useState(null);
  const [message, setMessage] = useState("");
  const [context, _] = useContext(AppContext);

  let languageContext = context && context.language && context.language;

  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const [propsA, setA] = useSpring(() => ({
    paddingBottom: 0,
  }));
  const [propsB, setB] = useSpring(() => ({
    paddingBottom: 0,
  }));
  const [propsC, setC] = useSpring(() => ({
    paddingBottom: 0,
  }));
  const [propsD, setD] = useSpring(() => ({
    height: "1rem",
  }));

  const onLanguageChange = (type) => {
    let spanishLanguage =
      languageContext === undefined || languageContext === false;
    switch (type) {
      case "footer-title-a":
        return spanishLanguage ? FooterTitleA["es"] : FooterTitleA["en"];
      case "footer-title-b":
        return spanishLanguage ? FooterTitleB["es"] : FooterTitleB["en"];
      case "footer-title-left":
        return spanishLanguage ? FooterTitleLeft["es"] : FooterTitleLeft["en"];
      case "footer-field-name":
        return spanishLanguage ? FooterFieldName["es"] : FooterFieldName["en"];
      case "footer-field-email":
        return spanishLanguage
          ? FooterFieldEmail["es"]
          : FooterFieldEmail["en"];
      case "footer-field-title":
        return spanishLanguage
          ? FooterFieldTitle["es"]
          : FooterFieldTitle["en"];
      case "footer-field-message":
        return spanishLanguage
          ? FooterFieldMessage["es"]
          : FooterFieldMessage["en"];
      case "footer-send":
        return spanishLanguage ? FooterSend["es"] : FooterSend["en"];
      case "footer-neighbourhood":
        return spanishLanguage
          ? FooterNeighbourhood["es"]
          : FooterNeighbourhood["en"];
      case "footer-email":
        return spanishLanguage ? FooterEmail["es"] : FooterEmail["en"];
      case "footer-form-validation":
        return spanishLanguage
          ? FooterFormValidation["es"]
          : FooterFormValidation["en"];
      case "footer-form-success":
        return spanishLanguage
          ? FooterFormSuccess["es"]
          : FooterFormSuccess["en"];
      default:
        break;
    }
  };

  const onFocusChange = (type, value) => {
    setSubmited(null);
    switch (type) {
      case "name":
        if (type != "email" && type != "title" && type != "message")
          setA({
            paddingBottom:
              value === true ? 50 : Object.values(name).length ? 50 : 0,
          });
      case "email":
        if (type != "name" && type != "title" && type != "message")
          setB({
            paddingBottom:
              value === true ? 50 : Object.values(email).length ? 50 : 0,
          });
      case "title":
        if (type != "email" && type != "name" && type != "message")
          setC({
            paddingBottom:
              value === true ? 50 : Object.values(title).length ? 50 : 0,
          });
      case "message":
        if (type != "email" && type != "name" && type != "title")
          setD({
            height:
              value === true
                ? "4rem"
                : Object.values(message).length
                ? "4rem"
                : "1rem",
          });
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      Object.values(name).length > 0 &&
      Object.values(email).length > 0 &&
      Object.values(title).length > 0 &&
      Object.values(message).length > 0
    ) {
      setSubmited(onLanguageChange("footer-email"));
      await submit({ name, email, title, message });
      setSubmited(onLanguageChange("footer-form-success"));
    } else {
      setSubmited(onLanguageChange("footer-form-validation"));
    }
  };

  return (
    <div className="flex flex-col justify-center mt-96 ">
      <div className="flex flex-col justify-center">
        <h2 className="flex justify-center text-center flex-col font-sans text-5xl text-tinto font-bold">
          {onLanguageChange("footer-title-a")}
          <label className="flex justify-center font-sans text-2xl text-tinto font-bold">
            {onLanguageChange("footer-title-b")}
          </label>
        </h2>
      </div>
      <div className="h-96 flex flex-wrap justify-around my-10 mx-14 lg:mx-32 z-20">
        <div className="flex flex-col">
          <label className="font-sans text-2xl text-tinto font-bold">
            {onLanguageChange("footer-title-left")}
          </label>
          <div>
            <div id="form">
              <div className="flex flex-col justify-center">
                <animated.label
                  className={`absolute font-Manu-Poppins block text-tinto text-lg font-bold z-0`}
                  style={propsA}
                >
                  {onLanguageChange("footer-field-name")}
                </animated.label>
                <input
                  name="persona"
                  id="personatxt"
                  onChange={(d) => setName(d.target.value)}
                  onFocus={() => {
                    return onFocusChange("name", true);
                  }}
                  onBlur={() => {
                    return onFocusChange("name", false);
                  }}
                  className="z-10 my-8 font-Manu-Poppins bg-transparent outline-none border-b-2 border-tinto focus:border-amarillo-alegria py-1 w-72 md:w-96 bg-opacity-0"
                  type="text"
                />
              </div>
              <div className="flex flex-col justify-center">
                <animated.label
                  className={`absolute font-Manu-Poppins block text-tinto text-lg font-bold z-0`}
                  style={propsB}
                >
                  {onLanguageChange("footer-field-email")}
                </animated.label>
                <input
                  name="correo"
                  id="correotxt"
                  onChange={(d) => setEmail(d.target.value)}
                  onFocus={() => {
                    return onFocusChange("email", true);
                  }}
                  onBlur={() => {
                    return onFocusChange("email", false);
                  }}
                  className="z-10 my-8 font-Manu-Poppins bg-transparent outline-none border-b-2 border-tinto focus:border-amarillo-alegria py-1 w-72 md:w-96 bg-opacity-0"
                  type="email"
                />
              </div>
              <div className="flex flex-col justify-center">
                <animated.label
                  className={`absolute font-Manu-Poppins block text-tinto text-lg font-bold z-0`}
                  style={propsC}
                >
                  {onLanguageChange("footer-field-title")}
                </animated.label>
                <input
                  name="asunto"
                  id="asuntotxt"
                  onChange={(d) => setTitle(d.target.value)}
                  onFocus={() => {
                    return onFocusChange("title", true);
                  }}
                  onBlur={() => {
                    return onFocusChange("title", false);
                  }}
                  className="z-10 my-8 font-Manu-Poppins bg-transparent outline-none border-b-2 border-tinto focus:border-amarillo-alegria py-1 w-72 md:w-96 bg-opacity-0"
                  type="text"
                />
              </div>
              <div className="flex flex-col justify-center">
                <label
                  className={`block font-Manu-Poppins text-tinto text-lg font-bold z-0`}
                >
                  {onLanguageChange("footer-field-message")}
                </label>
                <animated.textarea
                  name="mensaje"
                  id="mensajetxt"
                  onChange={(d) => setMessage(d.target.value)}
                  style={propsD}
                  cols="50"
                  onFocus={() => {
                    return onFocusChange("message", true);
                  }}
                  onBlur={() => {
                    return onFocusChange("message", false);
                  }}
                  className="z-10 my-2 font-Manu-Poppins bg-transparent outline-none border-b-2 border-tinto focus:border-amarillo-alegria py-1 w-72 md:w-96 bg-opacity-0"
                  type="text"
                />
              </div>
              <div className="my-4 flex flex-col justify-center">
                <button
                  id="enviaremail"
                  onClick={(e) => handleSubmit(e)}
                  className={`text-sm border-amarillo-alegria text-tinto md:text-2xl font-Manu-Poppins mx-4 p-2 border-solid rounded-lg border-2 ${
                    submitting ||
                    (Object.values(name).length === 0 &&
                      Object.values(email).length === 0 &&
                      Object.values(title).length === 0 &&
                      Object.values(message).length === 0)
                      ? "opacity-50"
                      : "opacity-100"
                  } bg-amarillo-alegria ${
                    submitting ||
                    (Object.values(name).length === 0 &&
                      Object.values(email).length === 0 &&
                      Object.values(title).length === 0 &&
                      Object.values(message).length === 0)
                      ? "opacity-50"
                      : "opacity-100"
                  } focus:outline-none hover:bg-opacity-75`}
                  disabled={
                    submitting ||
                    (Object.values(name).length === 0 &&
                      Object.values(email).length === 0 &&
                      Object.values(title).length === 0 &&
                      Object.values(message).length === 0)
                  }
                >
                  {onLanguageChange("footer-send")}
                </button>
                {submited && (
                  <label className="text-tinto font-Manu-Poppins text-sm text-center my-2 py-2 bg-azul-marino opacity-70 rounded-3xl px-2">
                    {submited}
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex mx-20">
          <div className="flex justify-center mb-64 text-tinto font-bold mt-20 lg:mt-0">
            <div
              style={{ transform: "translate(-10px,-10px)" }}
              className="absolute bg-amarillo-alegria w-72 h-72 md:w-96 md:h-96 opacity-50 rounded-3xl "
            ></div>
            <div
              style={{ zIndex: 6 }}
              className="font-Manu-Poppins flex justify-start mt-10 md:mt-20 flex-col z-10"
            >
              <div className="flex flex-row justify-start my-2">
                <div className="flex">
                  <img
                    loading="lazy"
                    src={LocalizacionLogo}
                    width={32}
                    style={{
                      maxWidth: "32px",
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-tinto text-sm md:text-xl">
                    Carrera 51d#90-74
                  </label>
                  <label className="text-tinto text-sm md:text-xl">
                    {onLanguageChange("footer-neighbourhood")} Santa Mónica,
                    Barranquilla
                  </label>
                </div>
              </div>
              <div className="flex flex-row justify-start my-2">
                <div className="flex">
                  <a
                    target="_blank"
                    href="https://api.whatsapp.com/send?phone=+573505769676"
                  >
                    <img
                      loading="lazy"
                      src={LlamadaLogo}
                      width={32}
                      style={{
                        maxWidth: "32px",
                      }}
                    />
                  </a>
                </div>
                <div className="flex flex-row">
                  <label className="text-tinto text-sm md:text-xl m-auto">
                    <a
                      target="_blank"
                      href="https://api.whatsapp.com/send?phone=+573505769676"
                    >
                      +57 350 576 9676
                    </a>
                  </label>
                </div>
              </div>
              <div className="flex flex-row justify-start my-2">
                <div className="flex">
                  <img
                    loading="lazy"
                    src={EmailLogo}
                    width={32}
                    style={{
                      maxWidth: "32px",
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-tinto text-sm md:text-xl">
                    <a href="mailto:manu@vpixel.co">manu@vpixel.co</a>
                  </label>
                  <label className="text-tinto text-sm md:text-xl">
                    <a href="mailto:emrajipower@gmail.com">
                      emrajipower@gmail.com
                    </a>
                  </label>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <a target="_blank" href="https://twitter.com/emrajipower">
                  <img
                    loading="lazy"
                    src={twitter}
                    className="w-8 h-8 mx-2 my-1"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/emrajipower/"
                >
                  <img
                    loading="lazy"
                    src={instagram}
                    className="w-8 h-8 mx-2 my-1"
                  />
                </a>
                <a target="_blank" href="https://github.com/EmrajiPower">
                  <img
                    loading="lazy"
                    src={GithubLogo}
                    className="text-tinto w-8 h-8 mx-2 my-1"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/emmanuel-rangel-jim%C3%A9nez-925647111/"
                >
                  <img
                    loading="lazy"
                    src={linkedin}
                    className="w-8 h-8 mx-2 my-1"
                  />
                </a>
              </div>
            </div>
            <div
              style={{ transform: "translate(10px,10px)" }}
              className="absolute bg-amarillo-alegria w-72 h-72 md:w-96 md:h-96 opacity-50 rounded-3xl z-0"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactMe;
