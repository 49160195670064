import { useState, useContext, useRef, useEffect, memo } from "react";
import { withSize } from "react-sizeme";
import { AppContext } from "../../Provider/config";
import { useSpring, animated } from "react-spring";
import ReactIco from "../../Img/react-ico.svg";
import LifeCycleIco from "../../Img/lifecycles-ico.svg";
import ComponentsIco from "../../Img/components-ico.svg";
import ReactFlowIco from "../../Img/reactflow-ico.svg";
import VscodeIco from "../../Img/vscode-ico.svg";
import DesignIco from "../../Img/design_ico.svg";
import DesigningIco from "../../Img/designing_ico.svg";
import GitIco from "../../Img/git_ico.svg";
import BrowserIco from "../../Img/browser_ico.svg";
import TestingIco from "../../Img/testing-ico.svg";
import SOIco from "../../Img/so_ico.svg";
import ConsoleIco from "../../Img/console_ico.svg";
import TeamAIco from "../../Img/team-a-ico.svg";
import TeamBIco from "../../Img/team-b-ico.svg";
import AgileAIco from "../../Img/agile-a-ico.svg";
import AgileBIco from "../../Img/agile-b-ico.svg";

import Emmanuel from "../../Img/EmmanuelB.jpg";
import SmartSeals from "../../Img/smartseals.svg";

import {
  MeetMeTitle,
  EducationTitle,
  EducationSubtitle1,
  EducationContent1,
  EducationSubtitle2,
  EducationContent2,
  ExperiencedTitle,
  GraduationDate,
  UseBuild1,
  UseBuild2,
  ToolsTitle1,
  ToolsTitle2,
  ToolsTitle3,
} from "../../languages.json";

function WhoIAM({ size }) {
  const [props, set] = useSpring(() => ({
    height: 16,
  }));
  const [toggle, setToggle] = useState(false);
  const [context, setContext] = useContext(AppContext);

  const refWhoIAm = useRef();

  let languageContext = context && context.language && context.language;

  set({ height: toggle ? 26 : 16 });
  useEffect(() => {
    let scrollWhoIAm = refWhoIAm.current.getBoundingClientRect().top;
    setContext({
      ...context,
      scrollWhoIAm,
    });
  }, []);

  const onLanguageChange = (type) => {
    let spanishLanguage =
      languageContext === undefined || languageContext === false;
    switch (type) {
      case "meet-me-title":
        return spanishLanguage ? MeetMeTitle["es"] : MeetMeTitle["en"];
      case "education-title":
        return spanishLanguage ? EducationTitle["es"] : EducationTitle["en"];
      case "education-subtitle-1":
        return spanishLanguage
          ? EducationSubtitle1["es"]
          : EducationSubtitle1["en"];
      case "education-content-1":
        return spanishLanguage
          ? EducationContent1["es"]
          : EducationContent1["en"];
      case "education-subtitle-2":
        return spanishLanguage
          ? EducationSubtitle2["es"]
          : EducationSubtitle2["en"];
      case "education-content-2":
        return spanishLanguage
          ? EducationContent2["es"]
          : EducationContent2["en"];
      case "experienced-title":
        return spanishLanguage
          ? ExperiencedTitle["es"]
          : ExperiencedTitle["en"];
      case "graduation-date":
        return spanishLanguage ? GraduationDate["es"] : GraduationDate["en"];
      case "usebuild-1":
        return spanishLanguage ? UseBuild1["es"] : UseBuild1["en"];
      case "usebuild-2":
        return spanishLanguage ? UseBuild2["es"] : UseBuild2["en"];
      case "tools-title-1":
        return spanishLanguage ? ToolsTitle1["es"] : ToolsTitle1["en"];
      case "tools-title-2":
        return spanishLanguage ? ToolsTitle2["es"] : ToolsTitle2["en"];
      case "tools-title-3":
        return spanishLanguage ? ToolsTitle3["es"] : ToolsTitle3["en"];
      default:
        break;
    }
  };

  return (
    <div
      style={{ marginTop: "40vh" }}
      className="flex font-Manu-Poppins h-auto text-tinto flex-col justify-center md:flex-row flex-wrap md:justify-evenly w-full bg-azul-marino"
      ref={refWhoIAm}
    >
      <img
        loading="lazy"
        src={Emmanuel}
        className="rounded-2xl shadow-xl mx-auto my-4 w-72 h-97 sm:w-img-emmanuel sm:h-img-emmanuel"
      />
      <section className="flex xs:mt-96 sm:mt-0 md:block justify-center flex-col flex-wrap mx-8 sm:mx-24 my-4">
        <h2 className="text-tinto text-center md:mb-8m my-12 font-sans font-bold text-4xl md:text-5xl">
          {onLanguageChange("meet-me-title")}
        </h2>
        <div className="block sm:flex justify-center flex-row flex-wrap ml-0">
          <div className="block sm:flex mx-2 sm:mx-0">
            <article className="my-6 text-left ">
              <h3 className="font-sans font-bold text-xl md:text-2xl">
                <div
                  className="w-10 h-2 bg-tinto relative left-0 text-tinto"
                  style={{ marginLeft: "1px" }}
                ></div>
                {onLanguageChange("education-title")}
              </h3>
              <ul>
                <li className="text-tinto font-sans mb-1 my-3 font-bold text-lg md:text-2xl italic">
                  {onLanguageChange("education-subtitle-1")}
                  <p className="flex  text-tinto flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    {onLanguageChange("education-content-1")}
                  </p>
                  <p className="flex flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    Universidad Simón Bolívar, Barranquilla 🇨🇴
                  </p>
                </li>
                <li className="text-tinto font-sans mb-1 my-3 font-bold text-lg md:text-2xl italic">
                  {onLanguageChange("education-subtitle-2")}
                  <p className=" text-tinto flex flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    {onLanguageChange("education-content-2")}
                  </p>
                  <p className="flex  text-tinto flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    INETASF, Bolívar 🇨🇴
                  </p>
                </li>
              </ul>
            </article>
            <article className="mx-2 my-6 text-left">
              <h3 className="font-sans text-tinto                                                                                                                                 font-bold text-xl md:text-2xl">
                <div
                  className="w-10 h-2 bg-tinto relative left-0"
                  style={{ marginLeft: "1px" }}
                ></div>
                {onLanguageChange("experienced-title")}
              </h3>
              <ul>
                <li className="text-tinto font-sans mb-1 my-3 font-bold text-lg md:text-2xl italic">
                  Front-End Developer
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    {onLanguageChange("graduation-date")}
                  </p>
                  <a
                    onMouseOver={() => {
                      setToggle(true);
                    }}
                    onMouseLeave={() => {
                      setToggle(false);
                    }}
                    target="_blank"
                    href="https://www.linkedin.com/in/smart-seals-co-sas-89721018a/"
                    className="font-Manu-Poppins text-tinto text-base md:text-2xl font-thin justify-start flex flex-row hover:underline hover:text-amarillo-alegria hover:opacity-60"
                  >
                    <span className="pr-1 my-auto mx-0">
                      <animated.img
                        loading="lazy"
                        style={props}
                        className="rounded-sm"
                        src={SmartSeals}
                      />
                    </span>{" "}
                    SmartSeals Co
                  </a>
                </li>
              </ul>
            </article>
          </div>
          <div className="block sm:flex mx-4 sm:mx-0 flex-wrap justify-center ">
            <article className="mx-2 my-6 text-left ml-0">
              <h3 className="font-sans text-tinto font-bold text-xl md:text-2xl">
                <div
                  className="w-10 h-2 bg-tinto relative left-0"
                  style={{ marginLeft: "1px" }}
                ></div>
                React Skills
              </h3>
              <ul>
                <li className="font-sans mb-1 my-3 font-bold text-lg md:text-2xl italic">
                  <h3 className="text-tinto text-xl md:text-2xl mt-8 md:mt-0">
                    useBuild()
                  </h3>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={ReactIco}
                      />
                    </span>
                    React v16-17
                  </p>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={LifeCycleIco}
                      />
                    </span>

                    {onLanguageChange("usebuild-1")}
                  </p>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="max-w-7 max-h-7 w-7 h-7 md:w-5 md:h-5"
                        src={ComponentsIco}
                      />
                    </span>
                    {onLanguageChange("usebuild-2")}
                  </p>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="max-w-7 max-h-7  w-7 h-7 md:w-5 md:h-5"
                        src={ReactFlowIco}
                      />
                    </span>
                    Api Hooks, Context, Reflux, HOC
                  </p>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="max-w-7 w-7 h-7 md:w-5 md:h-5"
                        src={VscodeIco}
                      />
                    </span>
                    VSCode, Vim
                  </p>
                </li>
                <li className="font-sans mb-1 my-3 font-bold text-lg md:text-2xl italic">
                  <h3 className="text-tinto text-xl md:text-2xl mt-8 md:mt-0">
                    useDesign()
                  </h3>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={DesignIco}
                      />
                    </span>
                    Figma, Invision
                  </p>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={DesigningIco}
                      />
                    </span>
                    Tailwind, SaaS, Material UI, css
                  </p>
                </li>
                <li className="font-sans mb-1 my-3 font-bold text-lg md:text-2xl italic">
                  <h3 className="text-tinto text-xl md:text-2xl mt-8 md:mt-0">
                    useWorkflow()
                  </h3>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={GitIco}
                      />
                    </span>
                    Git, Bitbucket, Git Hub
                  </p>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={BrowserIco}
                      />
                    </span>
                    Chromium, Firefox
                  </p>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={TestingIco}
                      />
                    </span>
                    Jest, Testing library
                  </p>
                </li>
              </ul>
            </article>
            <article className="mx-2 my-6 text-left">
              <h3 className="font-sans text-tinto font-bold text-xl md:text-2xl">
                <div
                  className="w-10 h-2 bg-tinto relative left-0"
                  style={{ marginLeft: "1px" }}
                ></div>
                Tools/Skills
              </h3>
              <ul>
                <li className="font-sans text-tinto mb-1 my-3 font-bold text-lg md:text-2xl italic">
                  {onLanguageChange("tools-title-1")}
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={SOIco}
                      />
                    </span>
                    Debian, Opensuse
                  </p>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={ConsoleIco}
                      />
                    </span>
                    Nvm, Tmux, Starship
                  </p>
                </li>
                <li className="font-sans text-tinto mb-1 my-3 font-bold text-lg md:text-2xl italic">
                  {onLanguageChange("tools-title-2")}
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={TeamAIco}
                      />
                    </span>
                    Slack, Basecamp, Trello
                  </p>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={TeamBIco}
                      />
                    </span>
                    G-suite, Basecamp
                  </p>
                </li>
                <li className="font-sans text-tinto mb-1 my-3 font-bold text-lg md:text-2xl italic">
                  {onLanguageChange("tools-title-3")}
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={AgileAIco}
                      />
                    </span>
                    Scrum, Kanban, Basecamp
                  </p>
                  <p className="flex text-tinto w-auto sm:w-80 flex-row m-auto font-Manu-Poppins text-lg my-3 md:my-0 md:text-2xl font-thin">
                    <span className="my-auto mx-1">
                      <img
                        loading="lazy"
                        className="w-7 h-7 md:w-5 md:h-5"
                        src={AgileBIco}
                      />
                    </span>
                    Key Result, to dos
                  </p>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </section>
    </div>
  );
}

export default memo(withSize()(WhoIAM));
